// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inter {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-variation-settings:
      "slnt" 0;
  }`, "",{"version":3,"sources":["webpack://./src/components/Sign/Signin.css"],"names":[],"mappings":"AAEA;IACI,gCAAgC;IAChC,kBAAkB;IAClB;cACU;EACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');\n\n.inter {\n    font-family: \"Inter\", sans-serif;\n    font-style: normal;\n    font-variation-settings:\n      \"slnt\" 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
